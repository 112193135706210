import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Spin } from 'syngenta-digital-cropwise-react-ui-kit';
import { LoadingOutlined } from '@ant-design/icons';

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const StyledLoader = styled.div`
  position: absolute;
  left: 45%;
  top: 45%;
  z-index: 10;
`;

export const Loader = (props) => {
  return (
    <StyledLoader>
      <Spin
        indicator={antIcon}
        spinning
        className='syt-default'
        delay={500}
        tip={props.message || 'Loading...'}
        {...props}
      />
    </StyledLoader>
  );
};

Loader.propTypes = {
  message: PropTypes.string
};

// import React from 'react';
// import { Spin } from 'syngenta-digital-cropwise-react-ui-kit';
// import styled from 'styled-components';
// import PropTypes from 'prop-types';

// const StyledSpin = styled(Spin)`
//   right: 0;
//   left: 0;
//   top: 50%;
//   width: 100%;
//   height: 100%;
//   position: fixed;
//   background: #222222ab;
//   z-index: 9999;
// `;

// export const Loader = ({ message }) => (
//   <div style={{ background: '#252323', height: '100vh' }}>
//     <StyledSpin size='large' tip={message || 'Loading...'} />
//   </div>
// );

// Loader.propTypes = {
//   message: PropTypes.string
// };
