import {
  GET_LICENSING_STATUS_LOADING,
  GET_LICENSING_STATUS_SUCCESS,
  GET_LICENSING_STATUS_ERROR
} from '../actions/types';

const initialState = {
  loading: false,
  error: null,
  success: false,
  data: []
};

export const licensingStatus = (state = initialState, action) => {
  switch (action.type) {
    case GET_LICENSING_STATUS_LOADING:
      return {
        ...state,
        success: false,
        loading: true
      };
    case GET_LICENSING_STATUS_SUCCESS:
      return {
        ...state,
        success: true,
        loading: false,
        data: action.payload
      };
    case GET_LICENSING_STATUS_ERROR:
      return {
        ...state,
        success: false,
        loading: false,
        error: action.error
      };

    default:
      return state;
  }
};
