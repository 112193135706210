import { combineReducers } from 'redux';
import { header } from './header';
import { fields } from './fieldsByPropId';
import { crops } from './crops';
import { user } from './user';
import { userAccessManagement } from './userAccessManagement';
import { weather } from './weather';
import { varieties } from './varieties';
import { profileSettings } from './profileSettings';
import { orgsList } from './orgsList';
import { propertiesList } from './propertiesList';
import { seasonsList } from './seasonsList';
import { seasonFields } from './seasonFields';
import { propertiesBySeasonList } from './propertiesBySeasonList';
import { connectedDecks } from './connectedDecks';
import { allFields } from './allFieldsByOrg';
import { tasks } from './tasks';
import { resources } from './resources';
import { termsAndConditions } from './termsAndConditions';
import { licensingStatus } from './licensingStatus';
import { login } from './login';

const rootReducer = combineReducers({
  // orgs, // TODO remove all commented reducers. All files.
  header,
  // properties,
  fields,
  crops,
  tasks,
  varieties,
  allFields,
  userAccessManagement,
  user,
  weather,
  profileSettings,
  orgsList,
  propertiesList,
  seasonsList,
  seasonFields,
  propertiesBySeasonList,
  connectedDecks,
  resources,
  termsAndConditions,
  licensingStatus,
  login
});

export { rootReducer };
